<template>
    <div class="Container">
        <div class="conten">
            <el-row>
                <el-button type="primary" size="small" @click="handleAdd">添加会员类别</el-button>
            </el-row>
            <div class="screen">
                <div class="screen_left">
                    <div class="screen_item">
                        <span>会员类别名称：</span>
                        <div>
                            <el-input v-model="member_value" placeholder="会员类别名称" size="small" @change="changeScreen"
                                style="width: 150px;"></el-input>
                        </div>
                    </div>
                    <div class="screen_item">
                        <span>状态：</span>
                        <div>
                            <el-select v-model="status_value" placeholder="请选择状态" size="small" @change="changeScreen"
                                style="width: 100px;">
                                <el-option v-for="item in status_options" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <el-button type="text" size="small" @click="clearScreen">清空筛选</el-button>
                    <!-- <div class="screen_item">
                        <span>创建时间：</span>
                        <div>
                            <el-date-picker v-model="date_value" size="small" style="width: 150px;" type="date"
                                placeholder="选择日期" @change="changeScreen" @input="changeScreen" value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="table_Container" :style="{ 'height': maxHeight_Table + 'px' }">
                <el-table :tableKey="tableKey" :data="tableData" style="width: 100%" :max-height="maxHeight_Table">
                    <el-table-column prop="title" label="会员类别名称" align="center"></el-table-column>
                    <el-table-column prop="plate" label="会员性质" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.plate == 0 ? '平台通用会员' : '板块专属会员' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="plate_text" label="所属板块" align="center"></el-table-column>
                    <el-table-column prop="status_text" label="状态" align="center"></el-table-column>
                    <el-table-column prop="create_time" label="创建时间" align="center"></el-table-column>
                    <el-table-column label="操作" align="right" width="200">
                        <template slot-scope="scope">
                            <el-button type="text" @click="handleEditStatus(scope.$index, scope.row)">
                                <span v-if="scope.row.status == 0" style="color: #008C3D;">启用</span>
                                <span v-if="scope.row.status == 1" style="color: #FE2F2F;">禁用</span>
                            </el-button>
                            <el-button type="text" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                            <el-button type="text" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                            <el-button type="text" @click="toConfig (scope.row)">会员配置</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- <div class="pagination">
                    <pagination :data="pageNation_data" @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                    </pagination>
                </div> -->
            </div>
        </div>

        <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="550px">
            <el-form :model="form" label-width="120px">
                <el-form-item label="会员类别名称">
                    <el-input v-model="form.title" size="small" style="width: 150px;"></el-input>
                </el-form-item>
                <el-form-item label="会员性质">
                    <div>
                        <el-radio v-model="form.plate" label="0">全平台会员</el-radio>
                    </div>
                    <div>
                        <el-radio v-model="form.plate" label="1">板块专属会员
                            <div style="margin:10px 0 0 1em;">
                                <el-checkbox-group v-model="form.plates">
                                    <el-checkbox label="1" :disabled="form.plate != 1">电竞酒店</el-checkbox>
                                    <el-checkbox label="2" :disabled="form.plate != 1">商城</el-checkbox>
                                    <el-checkbox label="3" :disabled="form.plate != 1">酒店</el-checkbox>
                                    <el-checkbox label="4" :disabled="form.plate != 1">旅游</el-checkbox>
                                </el-checkbox-group>
                            </div>
                        </el-radio>
                    </div>
                </el-form-item>
                <el-form-item label="是否启用">
                    <el-switch v-model="form.status"></el-switch>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer" style="justify-content: center;display: flex;">
                <el-button size="small" style="width: 100px;" @click="dialogVisible = false">取消</el-button>
                <el-button size="small" style="width: 100px;" type="primary" @click="add_memberType">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import Pagination from '../../../../components/PaginationPage/Pagination.vue'
export default {
    components: {
        // Pagination,
    },
    data() {
        return {
            // 传递分页组件的数据
            pageNation_data: {
                total: 0,
                currentPage: 1,
                currentSize: 10,
                small: true
            },

            status_options: [{
                value: null,
                label: '全部'
            }, {
                value: '1',
                label: '已启用'
            }, {
                value: '0',
                label: '已关闭'
            }],
            member_value: null,//筛选会员类别
            status_value: null,//筛选状态
            date_value: null,//筛选日期


            tableData: [],
            tableKey: false,
            // maxHeight_TableContainer: window.innerHeight -280,//表格容器最大高度
            maxHeight_Table: window.innerHeight - 260,//表格最大高度

            // 对话框
            dialogTitle: '新增会员类别',//对话框标题
            dialogVisible: false,
            form: {
                id: null,
                title: '',
                plate: "0",//会员性质
                plates: [],
                status: false,//是否启用
            },
        }
    },
    methods: {
        // 跳转到会员配置
        toConfig(row) {
            console.log('点击的row', row);
            this.$router.push(`/vipmanage/MemberConfig?id=${row.id}`)
        },
        // 清空筛选
        clearScreen() {
            this.member_value = null
            this.status_value = null
            this.getList()
        },
        // 获取会员类型列表
        getList() {
            let data = {
                title: this.member_value,
                status: this.status_value,
                page: this.pageNation_data.currentPage,
                page_rows: this.pageNation_data.currentSize,
            }
            this.my_request('admin/member/type', data, 'GET').then(res => {
                console.log(res, '获取会员类型列表');
                let { code, data } = res.data
                if (200 == code) {
                    this.tableData = data.list
                    this.pageNation_data.total = data.total
                    this.tableKey = !this.tableKey
                }
            })
        },
        // 确认添加/编辑会员类型 
        add_memberType() {
            if (this.dialogTitle == '新增会员类别') {
                let data = {
                    title: this.form.title,
                    status: this.form.status ? 1 : 0,
                    plate: this.form.plate == 0 ? 0 : this.form.plates.join(','),
                }
                this.my_request('admin/member/type', data, 'POST').then(res => {
                    console.log('添加会员类型结果', res)
                    let { code, message } = res.data
                    if (200 == code) {
                        this.$message.success('添加成功！')
                        this.dialogVisible = false
                        this.getList()
                    } else {
                        this.$message.error(message)
                    }
                })
            } else if (this.dialogTitle == '编辑会员类别') {
                let data = {
                    id: this.form.id,
                    title: this.form.title,
                    status: this.form.status ? 1 : 0,
                    plate: this.form.plate == 0 ? 0 : this.form.plates.join(','),
                }
                this.my_request('admin/member/type', data, 'PUT').then(res => {
                    console.log('编辑会员类型结果', res)
                    let { code, message } = res.data
                    if (200 == code) {
                        this.$message.success('编辑成功！')
                        this.dialogVisible = false
                        this.getList()
                    } else {
                        this.$message.error(message)
                    }
                })
            }

        },

        // 改变筛选值
        changeScreen() {
            console.log(this.date_value, 'date_value日期');
            console.log(this.member_value, 'member_value');
            this.getList()
        },
        // 分页组件事件
        handleSizeChange(val) {
            // 处理每页数量改变的逻辑
            console.log(`每页 ${val} 条`);
            this.pageNation_data.currentSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            // 处理当前页码改变的逻辑
            console.log(`当前页: ${val}`);
            this.pageNation_data.currentPage = val
            this.getList()
        },

        // 点击添加会员类别
        handleAdd() {
            this.dialogTitle = '新增会员类别'
            this.form = {
                title: '',
                plate: "0",
                plates: [],
                status: false,
            }
            this.dialogVisible = true

        },
        // 点击表格编辑
        handleEdit(index, row) {
            console.log(index, row);
            this.dialogTitle = '编辑会员类别'
            this.form.id = row.id
            this.form.title = row.title
            this.form.plate = row.plate == 0 ? '0' : '1'
            this.form.plates = row.plate == 0 ? [] : row.plate.split(',')

            this.form.status = row.status == 0 ? false : true
            this.dialogVisible = true
        },
        // 改变会员状态
        handleEditStatus(index, row) {
            let data = {
                id: row.id,
                status: row.status == 0 ? 1 : 0
            }
            this.my_request('admin/member/type', data, 'PUT').then(res => {
                console.log('改变会员状态结果', res)
                let { code, message } = res.data
                if (200 == code) {
                    this.$message.success('修改成功！')
                    this.dialogVisible = false
                    this.getList()
                } else {
                    this.$message.error(message)
                }
            })
        },
        // 点击表格删除
        handleDelete(index, row) {
            console.log(index, row);
            this.$confirm('此操作将永久删除该会员类别, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id: row.id
                }
                this.my_request('admin/member/type', data, 'DELETE').then(res => {
                    console.log(res, '删除会员结果');
                    let { code } = res.data
                    if (200 == code) {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getList()
                    } else {
                        this.$message({
                            type: 'error',
                            message: '删除失败'
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        // 监听窗口大小变化
        handleResize() {
            // 在这里处理窗口大小变化的逻辑
            // this.maxHeight_TableContainer =  window.innerHeight - 360
            this.maxHeight_Table = window.innerHeight - 260
        },
    },
    created() {
        this.getList()
    },
    mounted() {
        // 监听窗口大小变化
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        // 移除窗口大小变化的监听
        window.removeEventListener('resize', this.handleResize);
    },
}
</script>
<style lang="scss" scoped>
.el-form-item {
    margin-bottom: 10px;
}

.Container {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .conten {
        .screen {
            margin: 20px 0 10px 0;
            height: 40px;
            padding: 15px 20px;
            background-color: #F8F7F7;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .screen_left {
                display: flex;
                font-size: 14px;

                .screen_item {
                    display: flex;
                    align-items: center;

                    div {
                        margin-right: 10px;
                    }
                }
            }
        }

        .table_Container {
            position: relative;

            .pagination {
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }
    }
}
</style>